import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Alert,
  Container,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  ModalHeader,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardHeader,
  Progress,
} from "reactstrap";
import axios from "../../../helpers/axiosConfig";
import { getList } from "helpers/ListHelper";
import SimpleModal from "../components/SimpleModal";
import ForceIntroductionModal from "../components/ModalMuscleStrength";
import FraxIntroductionModal from "../components/ModalFrax.jsx";

const MedicalAnamnesis = ({ location }) => {
  const [has, setHas] = useState(false);

  const modalRef = useRef();
  const setModal = (options) => {
    modalRef.current.setModal(options);
  };

  //LOCATION###############################################################
  useEffect(() => {
    if (!location?.state?.id) {
      location.state.id = 0;
    }
    fetchLastMedical(location.state.id);
  }, []);

  const options = [
    "Si",
    "No puede mostrarlos, pero los indicará verbalmente.",
    "No puede mostrarlos y no recuerda ninguno.",
    "No está tomando o usando medicamentos.",
  ];

  //FILE###################################################################
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState({});
  const [hasFile, setHasFile] = useState(false);
  const [files, setFiles] = useState([]);
  const extAllowed = "jpeg jpg png pdf";
  const sizAllowed = 120000000;
  const defaultHelpText =
    extAllowed + " (max: " + sizAllowed / 1000000 + " MB)";
  const [helpText, setHelpText] = useState(defaultHelpText);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  const handleHasFile = () => {
    setHasFile(!hasFile);
    cleanFile();
    setHelpText(defaultHelpText);
  };

  const fetchFiles = async () => {
    const res = await axios.post("photo/medico_geriatra/obtain", {
      anamnId: medical.geriatric_medical_anamnesis_id,
    });
    if (!res.data.error) {
      setFiles(res.data.result);
      setModal({});
    }
  };

  const loadFile = (file) => {
    if (file[0]?.name) {
      const size = file[0].size;
      const full = file[0]?.name;
      const type = full
        .slice(((full.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();
      if (extAllowed.indexOf(type) >= 0) {
        if (size <= sizAllowed) {
          setFileName(full.slice(0, full.lastIndexOf(".")));
          setFileType(type);
          setFile(file);
          setHelpText(full);
          return;
        } else {
          cleanFile();
          setHelpText("Tamaño de archivo invalido");
          return;
        }
      }
      cleanFile();
      setHelpText("Formato de archivo invalido");
    }
  };

  const cleanFile = () => {
    setFileName("");
    setFileType("");
    setFile({});
  };

  const uploadFile = () => {
    if (fileName.length > 60) {
      setHelpText(
        "El archivo excede el maximo de caracteres permitidos. (max:60)"
      );
    } else if (fileName) {
      setShowProgress(true);
      const f = new FormData();
      f.append("file", file[0], fileName + "." + fileType);
      const config = {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      };
      axios
        .post("file/img_medico/" + location.state.run, f, config)
        .then((response) => {
          saveFile();
          setUploadProgress(0);
          setShowProgress(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const saveFile = async () => {
    const res = await axios.post("photo/medico_geriatra", {
      anamnId: medical.geriatric_medical_anamnesis_id,
      nombreImagen: fileName + "." + fileType,
      ruta: "static/images_anam_medico/" + location.state.run,
    });
    if (!res.data.error) {
      fetchFiles();
      cleanFile();
      setHelpText(defaultHelpText);
      setHasFile(false);
    }
  };

  const removeFile = async (fId, name) => {
    const res = await axios.post("photo/medico_geriatra/rm/", {
      fId: fId,
      route: location.state.run + "/" + name,
    });
    if (!res.data.error) {
      fetchFiles();
    }
  };

  const setStatusPerson = async (idPersona) => {
    const resp = await axios.get("person/consult-status-person/" + idPersona);
    if (!resp.data.error) {
      if (resp.data.result[0]["status_id"] == 3) {
        const res = await axios.put("person/set-status-person/", {
          status_id: 6,
          personId: idPersona,
        });

        if (!res.data.error) {
          console.log("Status actualizado!");
        }
      } else {
        console.log("El estado no se actualizó");
      }
    }
  };

  //CRUD###################################################################
  const saveChanges = async () => {
    try {
      const medicalToSave = {
        ...medical,
        blood_pressure: `${medical.sistolic}/${medical.diastolic}`,
        second_blood_pressure: `${medical.second_sistolic}/${medical.second_diastolic}`,
      };

      const res = await axios.put(
        "geriatrical-medical-anamnesis/crud",
        medicalToSave
      );

      setStatusPerson(location.state.id);
      if (!res.data.error) {
        console.log("Cambios guardados correctamente!");
      } else {
        console.log(
          "Err: " + res.data.result.code + " " + res.data.result.sqlMessage
        );
      }
    } catch (e) {
      console.log("Err: " + e);
    }
  };

  const saveChangesFromButton = async (e) => {
    e.preventDefault();
    saveChanges();
  };

  //GROUP#################################################################
  const [group, setGroup] = useState("fisical");
  const changeGroup = (group) => {
    var form = document.getElementById("id-form-update");
    if (form.checkValidity()) {
      saveChanges();
      setGroup(group);
    } else {
      document.getElementById("id-btn-save").click();
    }
    fetchFiles();
  };

  //MEDICAL###############################################################
  const defaultMedical = {
    geriatric_medical_anamnesis_id: 0,
    person_id: 0,
    walking_speed: 0,
    weight: 0,
    heigth: 0,
    bmi: 0,
    temperature: 0,
    heart_rate: 0,
    oxygen_saturation: 0,
    muscular_strength: "s/e",
    glycemia: 0,
    extra_info: "",
    has_fall: 0,
    fall: "",
    incontinence: "",
    has_sleep_disorders: 0,
    sleep_disorders: "",
    comment: "",
    folestein_mmse: 0,
    pfeiffer_test: 0,
    has_depression: false,
    helper: 0,
    fragility: 0,
    icm_score: 0,
    estimated_survival: 0,
    musculoskeletal_pain_q4: false,
    musculoskeletal_pain_q8: false,
    musculoskeletal_pain_q9: 0,
    osteoporosis_evaluation: 0,
    fracture_evaluation: 0,
    hip_fracture: 0,
    calf_circumference: 0,
    second_muscular_strength: 0,
    third_muscular_strength: 0,
    is_medicated: "",
    medication_number: 0,
    medicine_name: "",
    sistolic: 0,
    diastolic: 0,
    second_sistolic: 0,
    second_diastolic: 0,
  };

  const showMusculoskeletalAlert = () => {
    return medical.musculoskeletal_pain_q9 >= 4 &&
           !medical.musculoskeletal_pain_q8 &&
           medical.musculoskeletal_pain_q4;
  }


  const [medical, setMedical] = useState(defaultMedical);

  const [isPfeifferEnabled, setIsPfeifferEnabled] = useState(true);

  useEffect(() => {
    const isPfeifferEnabled = parseInt(medical.folestein_mmse, 10) < 13;
    setIsPfeifferEnabled(isPfeifferEnabled);
  }, [medical.folestein_mmse]);

  const handleInputChange = (e) => {
    setMedical({
      ...medical,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "folestein_mmse") {
      const isPfeifferEnabled = parseInt(e.target.value, 10) < 13;
      setIsPfeifferEnabled(isPfeifferEnabled);
    }
  };

  const handleInputCheckedChange = (e) => {
    setMedical({
      ...medical,
      [e.target.name]: e.target.checked,
    });
  };

  const fetchLastMedical = async (person_id) => {
    const res = await getList(
      "geriatrical-medical-anamnesis/crud/" + person_id
    );
    if (res.length === 1) {
      const fetchedData = res[0];

      let sistolic, diastolic, second_sistolic, second_diastolic;

      // Verificar si blood_pressure no es null y tiene un formato válido para split
      if (
        fetchedData.blood_pressure &&
        fetchedData.blood_pressure.includes("/")
      ) {
        [sistolic, diastolic] = fetchedData.blood_pressure.split("/");
      }

      if (
        fetchedData.second_blood_pressure &&
        fetchedData.second_blood_pressure.includes("/")
      ) {
        [second_sistolic, second_diastolic] =
          fetchedData.second_blood_pressure.split("/");
      }

      setMedical({
        ...fetchedData,
        sistolic: sistolic || "",
        diastolic: diastolic || "",
        second_sistolic: second_sistolic || "",
        second_diastolic: second_diastolic || "",
      });
      setHas(true);
    } else {
      setHas(false);
    }
  };

  //JSX####################################################################
  return (
    <Container>
      <SimpleModal ref={modalRef} />
      <h1>Anamnesis Médico - Geriátrica</h1>
      {!has ? (
        <Card>
          <h4>
            El paciente no tiene registros en Anamnesis Médico - Geriátrica.
          </h4>
        </Card>
      ) : (
        <Card>
          <CardBody className="pb-0 pt-1">
            {/* {location?.state?.run && <h3>{location.state.run} {location.state.name} {location.state.apellido}</h3>} */}
            <ModalHeader className="pb-5 pl-0">
              <a
                style={{
                  paddingRight: "15px",
                  cursor: "pointer",
                  ...(group === "fisical" ? { color: "#11cdef" } : {}),
                }}
                onClick={() => changeGroup("fisical")}
              >
                Parametros físicos
              </a>
              <a
                style={{
                  paddingRight: "15px",
                  cursor: "pointer",
                  ...(group === "test" ? { color: "#11cdef" } : {}),
                }}
                onClick={() => changeGroup("test")}
              >
                Evaluaciones
              </a>
              <a
                style={{
                  paddingRight: "15px",
                  cursor: "pointer",
                  ...(group === "repo" ? { color: "#11cdef" } : {}),
                }}
                onClick={() => changeGroup("repo")}
              >
                Repositorio
              </a>
            </ModalHeader>

            <Form
              id="id-form-update"
              onSubmit={(e) => {
                saveChangesFromButton(e);
              }}
              role="form"
            >
              {group === "fisical" && (
                <>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        Velocidad de marcha
                      </Label>
                      <InputGroup>
                        <Input
                          name="walking_speed"
                          value={medical.walking_speed}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>m/s</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Label className="form-control-label">Peso</Label>
                      <InputGroup>
                        <Input
                          name="weight"
                          value={medical.weight}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>kg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Label className="form-control-label">Altura</Label>
                      <InputGroup>
                        <Input
                          name="heigth"
                          value={medical.heigth}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.01"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>m</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Label className="form-control-label">
                        Circunferencia de Pantorrilla
                      </Label>
                      <InputGroup>
                        <Input
                          name="calf_circumference"
                          value={medical.calf_circumference}
                          min={0}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.01"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>cm</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col md="4">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        1ra Presión Arterial (Sistólica / Diastólica)
                      </Label>
                      <InputGroup>
                        <Input
                          name="sistolic"
                          type="number"
                          min={80}
                          max={250}
                          step="1"
                          value={medical.sistolic}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>/</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="diastolic"
                          type="number"
                          min={50}
                          max={200}
                          step="1"
                          value={medical.diastolic}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>mmHg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>

                    <Col md="4">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                      >
                        2da Presión Arterial (Sistólica / Diastólica)
                      </Label>
                      <InputGroup>
                        <Input
                          name="second_sistolic"
                          type="number"
                          min={80}
                          max={250}
                          step="1"
                          value={medical.second_sistolic}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          //required
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>/</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="second_diastolic"
                          type="number"
                          min={50}
                          max={200}
                          step="1"
                          value={medical.second_diastolic}
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          //required
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>mmHg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>

                    <Col>
                      <Label className="form-control-label">Temperatura</Label>
                      <InputGroup>
                        <Input
                          name="temperature"
                          value={medical.temperature}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>ºC</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        Frecuencia Cardíaca
                      </Label>
                      <InputGroup>
                        <Input
                          name="heart_rate"
                          value={medical.heart_rate}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>lat/m</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Label className="form-control-label">
                        Saturación de Oxígeno
                      </Label>
                      <InputGroup>
                        <Input
                          name="oxygen_saturation"
                          value={medical.oxygen_saturation}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            %SpO<sub>2</sub>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        1° Fuerza Muscular (<ForceIntroductionModal />)
                      </Label>
                      <InputGroup>
                        <Input
                          name="muscular_strength"
                          value={medical.muscular_strength}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.1"
                          autoComplete="off"
                          min={0}
                          max={100}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>kg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Label className="form-control-label">
                        2° Fuerza Muscular (<ForceIntroductionModal />)
                      </Label>
                      <InputGroup>
                        <Input
                          name="second_muscular_strength"
                          value={medical.second_muscular_strength}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.1"
                          autoComplete="off"
                          min={0}
                          max={100}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>kg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Label className="form-control-label">
                        3° Fuerza Muscular (<ForceIntroductionModal />)
                      </Label>
                      <InputGroup>
                        <Input
                          name="third_muscular_strength"
                          value={medical.third_muscular_strength}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="0.1"
                          autoComplete="off"
                          min={0}
                          max={100}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>kg</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Col md="4">
                      <Label className="form-control-label">Glicemia</Label>
                      <InputGroup>
                        <Input
                          name="glycemia"
                          max={700}
                          min={50}
                          value={medical.glycemia || ""}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          step="1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>mg/dL</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>

                  <h5 className="heading-small">Inventario de Medicamentos</h5>
                  <FormGroup className="row">
                    <Col md="6">
                      <Label className="form-control-label mb-3">
                        ¿Podría mostrarme los medicamentos y remedios que
                        actualmente está usando?
                      </Label>
                      <Input
                        type="select"
                        name="is_medicated"
                        value={medical.is_medicated}
                        onChange={(e) => handleInputChange(e)}
                      >
                        <option hidden value="">
                          Seleccione...
                        </option>
                        {options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label mt-1">
                        Número total de compuestos activos
                      </Label>
                      <br />
                      <br />
                      <Input
                        type="number"
                        autoComplete="off"
                        maxLength="250"
                        name="medication_number"
                        max={20}
                        min={0}
                        value={medical.medication_number}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        Nombres de los medicamentos que está actualmente tomando
                      </Label>
                      <Input
                        type="textarea"
                        autoComplete="off"
                        maxLength="250"
                        name="medicine_name"
                        value={medical.medicine_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        Información Adicional
                      </Label>
                      <Input
                        type="textarea"
                        autoComplete="off"
                        maxLength="250"
                        name="extra_info"
                        value={medical.extra_info}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>
                  </FormGroup>

                  <h5 className="heading-small">Síndromes geriátricos</h5>

                  <FormGroup className="row">
                    <Col md="3">
                      <Label className="form-control-label">
                        Antecedentes de Incontinencia urinaria/fecal
                      </Label>
                    </Col>
                    <Col md="2">
                      <label className="custom-toggle custom-toggle-warning mr-1">
                        <Input
                          type="checkbox"
                          name="has_incontinence"
                          checked={medical.has_incontinence}
                          onChange={(e) => {
                            handleInputCheckedChange(e);
                          }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Sí"
                        />
                      </label>
                    </Col>
                    <Col md="12">
                      {medical.has_incontinence ? (
                        <>
                          <Input
                            placeholder=""
                            id="example-text-input"
                            name="incontinence"
                            value={medical.incontinence}
                            type="textarea"
                            maxLength="250"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                        </>
                      ) : (
                        (medical.incontinence = "")
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Col md="3">
                      <Label className="form-control-label">
                        Antecedentes de trastornos del sueño
                      </Label>
                    </Col>
                    <Col md="2">
                      <label className="custom-toggle custom-toggle-warning mr-1">
                        <Input
                          type="checkbox"
                          name="has_sleep_disorders"
                          checked={medical.has_sleep_disorders}
                          onChange={(e) => {
                            handleInputCheckedChange(e);
                          }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Sí"
                        />
                      </label>
                    </Col>
                    <Col md="12">
                      {medical.has_sleep_disorders ? (
                        <>
                          <Input
                            placeholder=""
                            id="example-text-input"
                            name="sleep_disorders"
                            value={medical.sleep_disorders}
                            type="textarea"
                            maxLength="250"
                            onChange={(e) => {
                              handleInputChange(e);
                            }}
                          />
                        </>
                      ) : (
                        (medical.sleep_disorders = "")
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">Comentarios</Label>
                      <Input
                        type="textarea"
                        autoComplete="off"
                        maxLength="250"
                        name="comment"
                        value={medical.comment}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </Col>
                  </FormGroup>
                </>
              )}
              {group === "test" && (
                <>
                  <Label className="form-control-label font-weight-bold text-danger">
                    Encuesta autoreporte de enfermedades (
                    <a
                      href="https://forms.gle/GVPNAjCiXh6HjuPj7"
                      target="_blank"
                    >
                      ver
                    </a>
                    )
                  </Label>
                  <br />
                  <br />

                  <h5 className="heading-small">Evaluaciones cognitivas</h5>
                  <FormGroup className="row">
                    <Col md="6">
                      <Label className="form-control-label">
                        Cuestionario Minimental (
                        <a
                          href="https://forms.gle/qahvUNZf3UGiFhveA"
                          target="_blank"
                        >
                          ver
                        </a>
                        )
                      </Label>
                      <Input
                        max="9999"
                        min="-9999"
                        value={medical.folestein_mmse}
                        name="folestein_mmse"
                        type="number"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label">
                        Test de Pfeiffer (
                        <a
                          href="https://forms.gle/tZRpDUFAQ5FsjGjt5"
                          target="_blank"
                        >
                          ver
                        </a>
                        )
                      </Label>
                      <Input
                        max="9999"
                        min="-9999"
                        value={medical.pfeiffer_test}
                        name="pfeiffer_test"
                        type="number"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                        disabled={!isPfeifferEnabled}
                      />
                    </Col>
                  </FormGroup>

                  <h5 className="heading-small">Síntomas depresivos</h5>
                  <FormGroup className="row">
                    <Col md="6">
                      <label className="form-control-label">
                        Durante los últimos 12 meses, ¿ha experimentado usted
                        dos semanas seguidas o más en las que se sintió triste,
                        desanimado/a o deprimido/a?
                      </label>
                    </Col>
                    <Col md="1">
                      <label className="custom-toggle custom-toggle-warning mr-1">
                        <Input
                          type="checkbox"
                          name="has_depression"
                          checked={medical.has_depression}
                          onChange={(e) => {
                            handleInputCheckedChange(e);
                          }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Sí"
                        />
                      </label>
                    </Col>
                  </FormGroup>

                  <h5 className="heading-small">
                    Evaluación de Síntomas Músculo Esqueléticos
                  </h5>
                  <FormGroup className="row">
                    <Col md="12">
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        Encuesta Síntomas Músculo Esqueléticos (
                        <a
                          href="https://forms.gle/23wiYK5miiZru7DBA"
                          target="_blank"
                        >
                          ver
                        </a>
                        )
                      </Label>
                    </Col>

                    <Col className="d-flex">
                      <Label className="form-control-label">
                        Respuesta pregunta 4:{" "}
                      </Label>
                      <label className="custom-toggle custom-toggle-warning ml-3">
                        <Input
                          type="checkbox"
                          name="musculoskeletal_pain_q4"
                          checked={medical.musculoskeletal_pain_q4}
                          onChange={(e) => {
                            handleInputCheckedChange(e);
                          }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Sí"
                        />
                      </label>
                    </Col>

                    <Col md="12" className="d-flex">
                      <Label className="form-control-label">
                        Respuesta pregunta 8:{" "}
                      </Label>
                      <label className="custom-toggle custom-toggle-warning ml-3">
                        <Input
                          type="checkbox"
                          name="musculoskeletal_pain_q8"
                          checked={medical.musculoskeletal_pain_q8}
                          onChange={(e) => {
                            handleInputCheckedChange(e);
                          }}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="No"
                          data-label-on="Sí"
                        />
                      </label>
                    </Col>
                    <Col>
                      <Label className="form-control-label">
                        Respuesta pregunta 9:{" "}
                      </Label>
                      <Input
                        name="musculoskeletal_pain_q9"
                        value={medical.musculoskeletal_pain_q9}
                        type="number"
                        step="1"
                        max={10}
                        min={1}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </FormGroup>

                  {showMusculoskeletalAlert() && (
                    <Alert color="warning">
                      Se debe realizar la interconsulta con el reumatólogo/a.
                    </Alert>
                  )}

                  <h5 className="heading-small">Evaluación social</h5>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        ¿En caso de necesidad cuenta con alguien para que le
                        ayude? Por ejemplo si se enfermara.
                      </Label>
                      <Input
                        value={medical.helper}
                        name="helper"
                        type="textarea"
                        maxLength="250"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <h5 className="heading-small">Evaluación de fragilidad</h5>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        Cuestionario FRAIL (
                        <a
                          href="https://forms.gle/wva8TfdqWyeFRRaG9"
                          target="_blank"
                        >
                          ver
                        </a>
                        )
                      </Label>
                      <Input
                        value={medical.fragility}
                        name="fragility"
                        type="number"
                        max="5"
                        min="0"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </FormGroup>

                    { medical.fragility >= 3 && (
                      <FormGroup className="row">
                      <Col>
                        <Alert color="warning">
                          Si el paciente tiene 70 años o más se debe realizar la interconsulta con el Geriatra.
                        </Alert>
                      </Col>
                      </FormGroup>
                    )}
                  
                  <h5 className="heading-small">
                    Índice de Comorbilidad de Charlson{" "}
                  </h5>
                  <FormGroup className="row">
                    <Col md="12">
                      <Label
                        className="form-control-label"
                        htmlFor="exampleFormControlSelect3"
                      >
                        CCI (
                        <a
                          href=" https://www.samiuc.es/indice-de-comorbilidad-de-charlson-cci/"
                          target="_blank"
                        >
                          ver
                        </a>
                        )
                      </Label>
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label">
                        Puntuación ICM de Charlson
                      </Label>
                      <InputGroup>
                        <Input
                          max="9999"
                          min="0"
                          value={medical.icm_score}
                          name="icm_score"
                          type="number"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />
                      </InputGroup>
                    </Col>

                    <Col md="6">
                      <Label className="form-control-label">
                        Supervivencia estimada a los 10 años
                      </Label>
                      <InputGroup>
                        <Input
                          max="100"
                          min={0}
                          value={medical.estimated_survival}
                          name="estimated_survival"
                          step="0.01"
                          type="number"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>

                  <h5 className="heading-small">Evaluación Osteoporosis</h5>
                  <FormGroup className="row">
                    <Col>
                      <Label className="form-control-label">
                        Encuesta de Osteoporosis (
                        <a
                          href="https://forms.gle/5e95gdMUg6j6UjKw9"
                          target="_blank"
                        >
                          ver
                        </a>
                        )
                      </Label>
                      <Input
                        value={medical.osteoporosis_evaluation}
                        name="osteoporosis_evaluation"
                        type="number"
                        min={0}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <Label className="form-control-label">
                    Riesgo de fractura FRAX (
                    <a
                      href="https://frax.shef.ac.uk/FRAX/tool.aspx?lang=sp"
                      target="_blank"
                    >
                      ver
                    </a>
                    ) (<FraxIntroductionModal />)
                  </Label>

                  <FormGroup className="row">
                    <Col md="12">
                      <Label className="form-control-label">
                        Resultado de IMC
                      </Label>
                      <InputGroup>
                        <Input
                          name="fracture_imc"
                          value={medical.fracture_imc}
                          onChange={(e) => handleInputChange(e)}
                          autoComplete="off"
                          type="number"
                          min={0}
                          step="0.1"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label">
                        Riesgo a 10 años (%) de fractura de cadera
                      </Label>
                      <InputGroup>
                        <Input
                          name="hip_fracture"
                          value={medical.hip_fracture}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          min={0}
                          step="0.1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label">
                        Riesgo a 10 años (%) de cualquier fractura osteoporótica
                      </Label>
                      <InputGroup>
                        <Input
                          name="osteoporotic_fracture"
                          value={medical.osteoporotic_fracture}
                          onChange={(e) => handleInputChange(e)}
                          type="number"
                          min={0}
                          step="0.1"
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </FormGroup>
                    {( medical.hip_fracture >= 3 || medical.osteoporotic_fracture >= 10 ) && (
                      <FormGroup className="row">
                        <Col md="6">
                          <Alert color="warning">
                            Se debe realizar la interconsulta con el reumatólogo/a.
                          </Alert>
                        </Col>
                      </FormGroup>
                    )}
                </>
              )}
              {group === "repo" && (
                <>
                  <FormGroup className="row">
                    <Col md="6">
                      <Label className="form-control-label mb-3">
                        Archivos disponibles
                      </Label>
                      {files.map((file) => (
                        <div key={file.foto_id} className="list-group mb-2">
                          <InputGroup>
                            <Button
                              className="mr-2"
                              color="danger"
                              size="sm"
                              outline
                              onClick={() =>
                                setModal({
                                  title: "Confirmación",
                                  text:
                                    "Desea eliminar " + file.foto_nombre + "?",
                                  type: 2,
                                  fx: () =>
                                    removeFile(file.foto_id, file.foto_nombre),
                                })
                              }
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                process.env.REACT_APP_API_URL +
                                `${file.url}` +
                                "/" +
                                `${file.foto_nombre}`
                              }
                            >
                              {file.foto_nombre}
                            </a>
                          </InputGroup>
                        </div>
                      ))}
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>
                          <FormGroup className="row">
                            <Col className="d-flex">
                              <Label className="form-control-label">
                                ¿Subir una nuevo archivo?
                              </Label>
                              <label className="custom-toggle custom-toggle-info ml-3">
                                <Input
                                  type="checkbox"
                                  checked={hasFile}
                                  onChange={() => handleHasFile()}
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="No"
                                  data-label-on="Sí"
                                />
                              </label>
                            </Col>
                          </FormGroup>
                        </CardHeader>
                        {hasFile && (
                          <CardBody>
                            <FormGroup>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => uploadFile()}
                                  >
                                    Agregar
                                  </Button>
                                </InputGroupAddon>
                                <Input
                                  autoComplete="off"
                                  type="text"
                                  name="treatment_plan"
                                  value={fileName}
                                  onChange={(e) => setFileName(e.target.value)}
                                  disabled
                                />
                                <InputGroupAddon addonType="append">
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      document.getElementById("files").click();
                                    }}
                                  >
                                    Buscar
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                              <h5
                                align="right"
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "0px",
                                }}
                              >
                                {helpText}
                              </h5>
                              {showProgress && (
                                <Progress
                                  value={uploadProgress}
                                  animated
                                  color="success"
                                >
                                  {uploadProgress}%
                                </Progress>
                              )}
                              <input
                                type="file"
                                accept="image/*, .pdf"
                                id="files"
                                style={{ visibility: "hidden" }}
                                onChange={(e) => loadFile(e.target.files)}
                              />
                            </FormGroup>
                          </CardBody>
                        )}
                      </Card>
                    </Col>
                  </FormGroup>
                </>
              )}
              <div className="float-right mb-4 mr-2">
                <Button type="submit" id="id-btn-save" color="primary">
                  Guardar
                </Button>{" "}
                {/* <Button onClick={() => {setDisplayModal(!displayModal)}} color="secondary">Cancelar</Button> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default MedicalAnamnesis;
