import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ForceIntroductionModal(args) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <a className='text-primary' onClick={toggle}>
        Ver Instrucciones
      </a>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}><i className="fa fa-info-circle" />  Instrucciones</ModalHeader>
        <ModalBody>
        <p style={{ textAlign: 'justify' }}>
        Antes de comenzar el test, verifique que el entrevistado no tenga joyas  que le puedan molestar durante el examen. <br /> <br /> 
        Si es así, pídale que se las  quite. Solicite además al participante que precaliente sus manos  sacudiéndolas 3 veces, y que flexione y estire sus dedos 3 veces. Muéstrele con un ejemplo. El participante debe estar de pie, con los pies separados al ancho de la cadera y los dedos apuntando hacia adelante, el codo  flexionado en 90 grados y la muñeca neutral.  Indicarle que inhale y al exhalar indique: "Apriete tan fuerte como  pueda, ...¡más fuerte!,...¡más fuerte!, ...relaje".  
        Al apretar el instrumento, la mano no debe tocar el cuerpo y debe mantenerse erguido. Debe hacerle saber al participante si al apretar se agacha.  El valor que se registra corresponde al indicado por la aguja de punta  roja en la escala exterior (kg) que va entre 0 y  90. Antes de cada medición debe asegurarse que ésta marque  el 0. Para ello, gire en sentido contrario a las agujas del reloj la  perilla que se encuentra al centro del visor. <br /> <br /> 
        Realizar 3 mediciones con una pausa de 30 segundos entre cada medición.
        </p>
        </ModalBody>
        <ModalFooter>
        <Button className="btn btn-primary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ForceIntroductionModal;