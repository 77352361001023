import React from 'react';
import {
    Button,
    Table, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import moment from 'moment';
import AuthHelper from "../../../helpers/AuthHelper";

const ModalListAppointments = ({ appointments, displayModalAppointments, setDisplayModalAppointments, showModalAppointmentToUpdate,disableAppointment, setSimpleModal }) => {


    return (
        <Modal size="lg" isOpen={displayModalAppointments}>
            <ModalHeader toggle={() => { setDisplayModalAppointments(!displayModalAppointments) }}>
                Agenda
            </ModalHeader>
            <ModalBody className="pt-1">
                <Table className="align-items-center" responsive>
                    <thead className="thead-light">
                        <tr className="text-center">
                            <th scope="col">Paciente</th>
                            <th scope="col">Dia / Hora</th>
                            <th scope="col">Profesional</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>

                        {appointments
                            .sort((a, b) => Date.parse(moment(b.cons_appo_date).format("YYYY-MM-DD") + " " + b.cons_appo_start) - Date.parse(moment(a.cons_appo_date).format("YYYY-MM-DD") + " " + a.cons_appo_start))
                            .map(appointment => (
                                <tr className="text-center" key={appointment.cons_appo_id}>
                                    <td>{appointment.run}</td>
                                    <td>{moment(appointment.cons_appo_date).format('DD-MM-YYYY')} / {moment(appointment.cons_appo_start, "HH:mm").format('HH:mm')}</td>
                                    <td>{appointment.professional_fullname}</td>
                                    {AuthHelper.getRole() === 3 || Date.parse(moment(appointment.cons_appo_date).format("YYYY-MM-DD") + " " + appointment.cons_appo_end) > Date.now() ? <><td>
                                        <Button color="primary" onClick={() => showModalAppointmentToUpdate(appointment)}>Editar</Button>
                                    </td>
                                        <td>
                                            <Button color="danger" size="sm" onClick={
                                                () => setSimpleModal({ title: 'Agenda', text: '¿Desea eliminar agenda del ' + moment(appointment.cons_appo_date).format('DD-MM-YYYY') + ' a las ' + moment(appointment.cons_appo_start, "HH:mm").format('HH:mm') + '?', type: 2, fx: () => disableAppointment(appointment.cons_appo_id) })
                                            }><i className="far fa-eye-slash"></i></Button>
                                        </td></> : <><td>
                                            <Button color="primary btn-light" disabled style={{ pointerEvents: 'none' }} >Editar</Button>
                                        </td>
                                        <td>
                                            <Button color="danger btn-light" size="sm" disabled style={{ pointerEvents: 'none' }}>
                                                <i className="far fa-eye-slash"></i>
                                            </Button>
                                        </td></>}

                                </tr>
                            ))}
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}

export default ModalListAppointments