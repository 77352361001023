import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Alert, Container, Form, FormGroup, Input, Card, CardBody, ModalHeader, Label, InputGroup, InputGroupAddon, InputGroupText, CardHeader, Progress } from 'reactstrap';
import axios from '../../../helpers/axiosConfig'
import { Redirect } from 'react-router-dom';
import SimpleModal from "../components/SimpleModal";
import FraxIntroductionModal from '../components/ModalFrax.jsx';

const CovidRisk = ({ location }) => {
    const modalRef = useRef()
    const setModal = (options) => {
        modalRef.current.setModal(options);
    };

    //FILE###################################################################
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [file, setFile] = useState({});
    const [hasFile, setHasFile] = useState(false);
    const [files, setFiles] = useState([]);
    const [helper, setHelper] = useState(false)
    const [musculoskeletal_pain_q4, setMusculoskeletal_pain_q4] = useState(false)
    const [musculoskeletal_pain_q8, setMusculoskeletal_pain_q8] = useState(false)
    const extAllowed = "jpeg jpg png";
    const sizAllowed = 120000000;
    const defaultHelpText = extAllowed + " (max: " + sizAllowed / 1000000 + " MB)";
    const [helpText, setHelpText] = useState(defaultHelpText);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);

    const handleHasFile = () => {
        setHasFile(!hasFile);
        cleanFile();
        setHelpText(defaultHelpText);
    };

    const fetchFiles = async () => {
        const res = await axios.post("photo/medico_geriatra/obtain", { anamnId: location.state.anamId })
        if (!res.data.error) {
            setFiles(res.data.result);
            setModal({});
        };
    };

    const loadFile = (file) => {
        if (file[0]?.name) {
            const size = file[0].size;
            const full = file[0]?.name;
            const type = full.slice((full.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();
            if (extAllowed.indexOf(type) >= 0) {
                if (size <= sizAllowed) {
                    setFileName(full.slice(0, (full.lastIndexOf('.'))));
                    setFileType(type);
                    setFile(file);
                    setHelpText(full);
                    return;
                } else {
                    cleanFile();
                    setHelpText("Tamaño de archivo invalido");
                    return;
                };
            };
            cleanFile();
            setHelpText("Formato de archivo invalido");
        };
    };

    const cleanFile = () => {
        setFileName("");
        setFileType("");
        setFile({});
    };

    const uploadFile = () => {
        if (fileName.length > 60) {
            setHelpText("El archivo excede el maximo de caracteres permitidos. (max:60)")
        } else if (fileName) {
            setShowProgress(true);
            const f = new FormData();
            f.append("file", file[0], fileName + '.' + fileType);
            const config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            }
            axios.post("file/img_medico/" + location.state.run, f, config)
                .then(response => {
                    saveFile();
                    setUploadProgress(0);
                    setShowProgress(false);
                }).catch(error => {
                    console.log(error);
                });
        };
    };

    const saveFile = async () => {
        const res = await axios.post("photo/medico_geriatra",
            {
                anamnId: location.state.anamId,
                nombreImagen: fileName + '.' + fileType,
                ruta: "static/images_anam_medico/" + location.state.run
            }
        );
        if (!res.data.error) {
            fetchFiles();
            cleanFile();
            setHelpText(defaultHelpText);
            setHasFile(false);
        };
    };

    const removeFile = async (fId, name) => {
        const res = await axios.post("photo/medico_geriatra/rm/", {
            fId: fId,
            route: location.state.run + '/' + name
        });
        if (!res.data.error) {
            fetchFiles();
        };
    };

    const [next, setNext] = useState(false)

    const [datos, setDatos] = useState({
        folstein_value: "",
        pfeiffer_value: "",
        has_depression: false,
        fragility_value: 0,
        musculoskeletal_pain_q4: false,
        musculoskeletal_pain_q8: false,
        musculoskeletal_pain_q9: 0,
        helper: "",
        icm_score_value: 0,
        estimated_survival: 0,
        osteoporosis_evaluation: 0,
        fracture_imc: 0,
        hip_fracture: 0,
        osteoporotic_fracture: 0
    });

    const [isPfeifferEnabled, setIsPfeifferEnabled] = useState(true);
    const [boton, setBoton] = useState(0)


    useEffect(() => {
        const isPfeifferEnabled = parseInt(datos.folstein_value, 10) < 13;
        setIsPfeifferEnabled(isPfeifferEnabled);
    }, [datos.folstein_value]);

    const onSubmit = e => {
        e.preventDefault();
        if (boton === 1) {
            addEvaluations(e);
        }
    }

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        })
        if (e.target.name === 'folstein_value') {
            const isPfeifferEnabled = parseInt(e.target.value, 10) < 13;
            setIsPfeifferEnabled(isPfeifferEnabled);
        }
    }

    const addEvaluations = async (e) => {
        e.preventDefault()
        const res = await axios.post("geriatrical-medical-anamnesis/evaluations",
            {
                anamnId: location.state.anamId,
                folstein_value: datos.folstein_value,
                pfeiffer_value: datos.pfeiffer_value,
                has_depression: datos.has_depression,
                fragility_value: datos.fragility_value,
                musculoskeletal_pain_q4: datos.musculoskeletal_pain_q4,
                musculoskeletal_pain_q8: datos.musculoskeletal_pain_q8,
                musculoskeletal_pain_q9: datos.musculoskeletal_pain_q9,
                helper: datos.helper,
                icm_score_value: datos.icm_score_value,
                estimated_survival: datos.estimated_survival,
                osteoporosis_evaluation: datos.osteoporosis_evaluation,
                fracture_imc: datos.fracture_imc,
                hip_fracture: datos.hip_fracture,
                osteoporotic_fracture: datos.osteoporotic_fracture
            })
        if (!res.data.error)
            setNext(true)
    }

    return (
        <Container>
            <SimpleModal ref={modalRef} />
            <Card>
                <CardBody>
                    <h3>{location.state.run}, {location.state.name} {location.state.apellido}</h3>
                    <h1>
                        Anamnesis Médico - Geriátrica
                    </h1>
                    <br />

                    <Label className="form-control-label font-weight-bold text-danger">Encuesta autoreporte de enfermedades (<a href="https://forms.gle/GVPNAjCiXh6HjuPj7" target="_blank">ver</a>)</Label>
                    <br />
                    <br />

                    <h5 className="heading-small">Evaluaciones Cognitivas</h5>

                    <Form onSubmit={onSubmit} role="form">
                        <FormGroup className="row">
                            <Col md="6">
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    Cuestionario Minimental (
                                    <a href="https://forms.gle/qahvUNZf3UGiFhveA"
                                        target="_blank">
                                        ver</a>)
                                </Label>
                                <Input
                                    placeholder=""
                                    min={0}
                                    id="example-text-input"
                                    name="folstein_value"
                                    type="number"
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}

                                />
                            </Col>
                            <Col md="6">
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    Test de Pfeiffer (
                                    <a href="https://forms.gle/tZRpDUFAQ5FsjGjt5"
                                        target="_blank">
                                        ver</a>)
                                </Label>
                                <Input
                                    placeholder=""
                                    id="example-text-input"
                                    name="pfeiffer_value"
                                    type="number"
                                    min={0}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    disabled={!isPfeifferEnabled}

                                />
                            </Col>

                        </FormGroup>

                        <h5 className="heading-small">Evaluación Anímica</h5>
                        <FormGroup className="row">
                            <Col md="6">
                                <label className="form-control-label">Durante los últimos 12 meses,
                                    ¿ha experimentado usted dos semanas seguidas o
                                    más en las que se sintió triste, desanimado/a o deprimido/a?
                                </label>
                                <br />
                            </Col>
                            <Col md="1">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input
                                        type="checkbox"
                                        name="has_depression"
                                        onChange={(e) => { handleInputChange(e) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>

                        <h5 className="heading-small">Evaluación de Síntomas Músculo Esqueléticos</h5>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label" htmlFor="exampleFormControlSelect3">
                                    Encuesta Síntomas Músculo Esqueléticos
                                    (<a href="https://forms.gle/23wiYK5miiZru7DBA"
                                        target="_blank">
                                        ver</a>)
                                </Label>
                            </Col>
                            <Col className="d-flex">
                                <Label className="form-control-label">Respuesta pregunta 4:   </Label>
                                <label className="custom-toggle custom-toggle-warning ml-3">
                                    <Input type="checkbox"
                                        name="musculoskeletal_pain_q4"
                                        checked={datos.musculoskeletal_pain_q4}
                                        onChange={handleInputChange} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                            <Col md="12" className="d-flex">
                                <Label className="form-control-label">Respuesta pregunta 8: </Label>
                                <label className="custom-toggle custom-toggle-warning ml-3">
                                    <Input
                                        type="checkbox"
                                        name="musculoskeletal_pain_q8"
                                        checked={datos.musculoskeletal_pain_q8}
                                        onChange={handleInputChange}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Sí" />
                                </label>
                            </Col>

                            <Col>
                                <Label className="form-control-label">Respuesta pregunta 9: </Label>
                                <Input
                                    name="musculoskeletal_pain_q9"
                                    value={datos.musculoskeletal_pain_q9}
                                    type="number"
                                    step="1"
                                    max={10} min={1}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </FormGroup>


                        {datos.musculoskeletal_pain_q4 && !datos.musculoskeletal_pain_q8 && datos.musculoskeletal_pain_q9 >= 4 && (
                            <Alert color="warning">
                                Se debe realizar la interconsulta con el reumatólogo/a
                            </Alert>
                        )}

                        <h5 className="heading-small">Evaluación Social</h5>
                        <FormGroup className="row">
                            <Col md="6">
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    ¿En caso de necesidad cuenta con alguien para que le ayude? Por ejemplo si se enfermara.
                                </Label>
                            </Col>
                            <Col md="1">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setHelper(!helper) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            {helper &&
                                <>
                                    <Col md="4">
                                        <Input
                                            placeholder=""
                                            id="example-text-input"
                                            name="helper"
                                            type="textarea"
                                            maxLength="250"
                                            onChange={(e) => {
                                                handleInputChange(e)
                                            }}

                                        />
                                    </Col>
                                </>
                            }
                        </FormGroup>

                        <h5 className="heading-small">Evaluación de fragilidad </h5>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">
                                    Cuestionario FRAIL (
                                    <a href="https://forms.gle/wva8TfdqWyeFRRaG9" target="_blank">ver</a>)
                                </Label>
                                <Input
                                    name="fragility_value"
                                    type="number"
                                    max="5" min="0"
                                    onChange={e => { handleInputChange(e) }}
                                />
                            </Col>
                        </FormGroup>

                        { datos.fragility_value >= 3 && (
                            <FormGroup className="row">
                            <Col>
                                <Alert color="warning">
                                Si el paciente tiene 70 años o más se debe realizar la interconsulta con el Geriatra.
                                </Alert>
                            </Col>
                            </FormGroup>
                        )}

                        <h5 className="heading-small">Índice de Comorbilidad de Charlson </h5>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    CCI (<a href=" https://www.samiuc.es/indice-de-comorbilidad-de-charlson-cci/" target="_blank">ver</a>)
                                </Label>

                            </Col>
                            <Col md="6">
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    Puntuación ICM de Charlson
                                </Label>
                                <Input
                                    placeholder=""
                                    id="example-text-input"
                                    name="icm_score_value"
                                    type="number"
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}

                                />
                            </Col>
                            <Col md="6">
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    Supervivencia estimada a los 10 años
                                </Label>
                                <InputGroup>
                                    <Input
                                        max="100"
                                        min={0}
                                        name="estimated_survival"
                                        step="0.01"
                                        type="number"
                                        onChange={e => { handleInputChange(e) }}
                                    />
                                    <InputGroupAddon addonType="append"><InputGroupText>%</InputGroupText></InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>

                        <h5 className="heading-small">Evaluación Osteoporosis</h5>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">
                                    Encuesta de Osteoporosis (
                                    <a href="https://forms.gle/5e95gdMUg6j6UjKw9" target="_blank">ver</a>)
                                </Label>
                                <Input
                                    name="osteoporosis_evaluation"
                                    type="number"
                                    min={0}
                                    onChange={e => { handleInputChange(e) }}
                                />
                            </Col>
                            <br />
                        </FormGroup>

                        <Label className="form-control-label">
                            Riesgo de fractura FRAX (
                            <a href="https://frax.shef.ac.uk/FRAX/tool.aspx?lang=sp" target="_blank">ver</a>
                            (<FraxIntroductionModal />)
                            )
                        </Label>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label">Resultado de IMC</Label>
                                <InputGroup>
                                    <Input
                                        name="fracture_imc"
                                        onChange={e => handleInputChange(e)}
                                        autoComplete="off"
                                        type="number"
                                        min={0}
                                        step="0.1"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md="6">
                                <Label className="form-control-label">Riesgo a 10 años (%) de fractura de cadera</Label>
                                <InputGroup>
                                    <Input
                                        name="hip_fracture"
                                        onChange={e => handleInputChange(e)}
                                        type="number"
                                        min={0}
                                        step="0.1"
                                        autoComplete="off"
                                    />
                                    <InputGroupAddon addonType="append"><InputGroupText>%</InputGroupText></InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col md="6">
                                <Label className="form-control-label">Riesgo a 10 años (%) de cualquier fractura osteoporótica</Label>
                                <InputGroup>
                                    <Input
                                        name="osteoporotic_fracture"
                                        onChange={e => handleInputChange(e)}
                                        type="number"
                                        min={0}
                                        step="0.1"
                                        autoComplete="off"
                                    />
                                    <InputGroupAddon addonType="append"><InputGroupText>%</InputGroupText></InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        { (datos.hip_fracture >= 3 || datos.osteoporotic_fracture >=10 ) && (
                          <FormGroup className="row">
                            <Col md="6">
                              <Alert color="warning">
                                Se debe realizar la interconsulta con el reumatólogo/a.
                              </Alert>
                            </Col>
                          </FormGroup>
                        )}
                        <h5 className="heading-small">Imagenes disponibles</h5>
                        <FormGroup className="row">
                            <Col md="6">
                                {files.map((file) => (
                                    <div key={file.foto_id} className="list-group mb-2">
                                        <InputGroup>
                                            <Button className="mr-2" color="danger" size="sm" outline onClick={() => setModal({ title: 'Confirmación', text: 'Desea eliminar ' + file.foto_nombre + '?', type: 2, fx: () => removeFile(file.foto_id, file.foto_nombre) })}><i className="far fa-eye-slash" /></Button>
                                            <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_API_URL + `${file.url}` + "/" + `${file.foto_nombre}`}>{file.foto_nombre}</a>
                                        </InputGroup>
                                    </div>
                                ))}
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardHeader>
                                        <FormGroup className="row">
                                            <Col className="d-flex">
                                                <Label className="form-control-label">¿Subir una nueva imagen?</Label>
                                                <label className="custom-toggle custom-toggle-info ml-3">
                                                    <Input type="checkbox" checked={hasFile} onChange={() => handleHasFile()} />
                                                    <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Sí" />
                                                </label>
                                            </Col>
                                        </FormGroup>
                                    </CardHeader>
                                    {hasFile &&
                                        <CardBody>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <Button
                                                            type="button" color="primary"
                                                            onClick={() => uploadFile()}>
                                                            Agregar
                                                        </Button>
                                                    </InputGroupAddon>
                                                    <Input
                                                        autoComplete="off"
                                                        type="text"
                                                        name="treatment_plan"
                                                        value={fileName}
                                                        onChange={e => setFileName(e.target.value)}
                                                        disabled
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            type="button" color="primary"
                                                            onClick={() => { document.getElementById('files').click() }}>
                                                            Buscar
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                <h5 align="right" style={{ marginRight: "10px", marginBottom: "0px" }}>{helpText}</h5>
                                                {showProgress && <Progress value={uploadProgress} animated color="success">{uploadProgress}%</Progress>}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="files"
                                                    style={{ visibility: 'hidden' }}
                                                    onChange={e => loadFile(e.target.files)}
                                                />
                                            </FormGroup>
                                        </CardBody>
                                    }
                                </Card>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="10">
                            </Col>
                            <Col md="2">
                                <Button type="submit" onClick={() => (setBoton(1))} color="primary">Guardar</Button>
                            </Col>
                            {next && <Redirect to={{
                                pathname: "/anamnesis-pacientes",
                                state: { id: location.state.id, run: location.state.run, name: location.state.name, medicalAnamnesis: true }
                            }} />}
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    )
}

export default CovidRisk
